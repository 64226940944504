import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  homeGroupKeys,
  useHomeGroupHomesCache,
} from "src/data/homeGroups/queries/homeGroupQueryCache"
import {
  IFetchHomeGroupHomes,
  IHomeGroupHome,
  IHomeGroupHomesFilter,
  IPostHomeGroupHome,
} from "src/data/homeGroups/types/homeGroupTypes"
import { orgsKeys } from "src/data/organizations/queries/organizationQueryCache"
import { ErrorService } from "src/utils/ErrorService"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchHomeGroupHomes({
  organizationId,
  homeGroupId,
  filter,
  options,
}: {
  organizationId: string
  homeGroupId: string
  filter?: IHomeGroupHomesFilter
  options?: UseQueryOptions<
    IFetchHomeGroupHomes,
    AxiosError,
    IFetchHomeGroupHomes,
    ReturnType<typeof homeGroupKeys.homeGroupHomes>
  >
}) {
  async function fetchHomeGroupHomes(): Promise<IFetchHomeGroupHomes> {
    const response = await minutApiHttpClient.get<IFetchHomeGroupHomes>(
      `${API_DEFAULT}/organizations/${organizationId}/homegroups/${homeGroupId}/home_ids`,
      {
        params: filter,
      }
    )

    return response.data
  }

  return useQuery(
    homeGroupKeys.homeGroupHomes(organizationId, homeGroupId, filter),
    fetchHomeGroupHomes,
    options
  )
}

interface IPostHomeGroupHomesMutation {
  organizationId: string
  homeGroupId: string
  body: IPostHomeGroupHome
}

export function usePostHomeGroupHomes() {
  const hghCache = useHomeGroupHomesCache()
  const queryClient = useQueryClient()

  async function postHomeGroupHomes({
    organizationId,
    homeGroupId,
    body,
  }: IPostHomeGroupHomesMutation): Promise<IHomeGroupHome> {
    const response = await minutApiHttpClient.post<IHomeGroupHome>(
      `${API_DEFAULT}/organizations/${organizationId}/homegroups/${homeGroupId}/home_ids`,
      body
    )

    return response.data
  }

  return useMutation<IHomeGroupHome, AxiosError, IPostHomeGroupHomesMutation>(
    postHomeGroupHomes,
    {
      onSuccess(home, vars) {
        queryClient.invalidateQueries(orgsKeys.homeLists(vars.organizationId))

        hghCache.addCachedHomeGroupHome(
          vars.organizationId,
          vars.homeGroupId,
          () => home
        )
      },
      onError(error) {
        ErrorService.captureException(error)
      },
    }
  )
}

interface IDeleteHomeGroupHomesMutation {
  orgId: string
  homeGroupId: string
  home_id: string
}
export function useDeleteHomeGroupHome<ErrorType = { 401: "Not allowed" }>() {
  const hghCache = useHomeGroupHomesCache()
  const queryClient = useQueryClient()

  async function deleteHomeGroupHome({
    orgId,
    homeGroupId,
    home_id,
  }: IDeleteHomeGroupHomesMutation) {
    const response = await minutApiHttpClient.delete(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}/home_ids/${home_id}`
    )
    return response.data
  }

  return useMutation<
    void,
    AxiosError<ErrorType>,
    IDeleteHomeGroupHomesMutation
  >(deleteHomeGroupHome, {
    onSuccess(_, vars) {
      queryClient.invalidateQueries(orgsKeys.homeLists(vars.orgId))
      hghCache.removeCachedHomeGroupHome(
        vars.orgId,
        vars.homeGroupId,
        vars.home_id
      )
    },
    onError(error) {
      ErrorService.captureException(error)
    },
  })
}
